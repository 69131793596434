import React from 'react'
import PropTypes from 'prop-types'
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
} from 'react-share'

import "../styles/share.scss"

const Share = ({ url, title }) => (
    <div className="post-social">
        <ul>
            <li>
                <FacebookShareButton url={url} quote={title} className="share-button">
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </li>
            <li>
                <TwitterShareButton url={url} title={title} className="share-button">
                    <TwitterIcon size={32} round />
                </TwitterShareButton>

            </li>
            <li>
                <EmailShareButton url={url} subject={title} className="share-button">
                    <EmailIcon size={32} round />
                </EmailShareButton>
            </li>
        </ul>
    </div>
)

Share.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Share